import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme, styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const userTestimonials = [
  {
    avatar: <Avatar sx={{ bgcolor: 'primary.dark', width: 56, height: 56 }} alt="Rajesh Verma" src="https://img.freepik.com/free-photo/smiling-businessman-face-portrait-wearing-suit_53876-148138.jpg?t=st=1727628846~exp=1727632446~hmac=f8333638c9663a7f0b15b54b0b2cb0d1710bacec0141fe0928438c949a64edf8&w=1480" />,
    name: 'Rajesh Verma',
    occupation: 'Software Engineer',
    testimonial:
      "Joblixx's profile-based job ranking system was a game-changer for me. It effortlessly matched my skills with relevant opportunities, leading me to a role that aligns perfectly with my career goals in technology.",
  },
  {
    avatar: <Avatar sx={{ bgcolor: 'primary.dark', width: 56, height: 56 }} alt="Ananya Gupta" src="https://img.freepik.com/free-photo/closeup-happy-pretty-indian-business-woman_1262-2258.jpg?t=st=1727628935~exp=1727632535~hmac=b8a1ec585a3ff14bee80ce7dd1d42589abeb3a5c9a7bc8449979de91daafb2b9&w=1800" />,
    name: 'Ananya Gupta',
    occupation: 'Marketing Manager',
    testimonial:
      "Job Description Summarizer saved me hours of searching by providing clear insights into job listings. It's a must-have for any marketer looking to streamline their job search.",
  },
  {
    avatar: <Avatar sx={{ bgcolor: 'primary.dark', width: 56, height: 56 }} alt="Vikram Sharma" src="https://img.freepik.com/free-photo/closeup-young-hispanic-man-casuals-studio_662251-600.jpg?t=st=1727628873~exp=1727632473~hmac=343f448732c0ffab21cec552bf47b859aa1481e867f487f75b72db17768a6d6a&w=996" />,
    name: 'Vikram Sharma',
    occupation: 'Financial Analyst',
    testimonial:
      "Thanks to Joblixx's real-time job notifications, I never missed a chance to apply for new opportunities. It's a platform that understands the needs of finance professionals like me.",
  },
  {
    avatar: <Avatar sx={{ bgcolor: 'primary.dark', width: 56, height: 56 }} alt="Priya Nair" src="https://img.freepik.com/free-photo/woman-doing-close-up-photoshoot-studio_53876-14476.jpg?t=st=1727628944~exp=1727632544~hmac=7930bb5d4fad5b5c8c1a72ea514a8ec49e94d0334ec13af9e605772de97fb91e&w=1800" />,
    name: 'Priya Nair',
    occupation: 'Human Resources Specialist',
    testimonial:
      "Auto Email Apply feature made job applications effortless and allowed me to focus more on personalized cover letters. It's a game-changer in simplifying the job search process.",
  },
  {
    avatar: <Avatar sx={{ bgcolor: 'primary.dark', width: 56, height: 56 }} alt="Sandeep Mehta" src="https://img.freepik.com/free-photo/worldface-pakistani-guy-white-background_53876-146312.jpg?t=st=1727628906~exp=1727632506~hmac=5136ae39c38890e90254b1339bb554d4504fc60e2b1581a27b698523195df3c7&w=1800" />,
    name: 'Sandeep Mehta',
    occupation: 'Legal Advisor',
    testimonial:
      "Joblixx's intuitive interface and personalized job recommendations helped me find a role that aligns perfectly with my legal expertise and career aspirations.",
  },
  {
    avatar: <Avatar sx={{ bgcolor: 'primary.dark', width: 56, height: 56 }} alt="Kavita Rao" src="https://img.freepik.com/free-photo/young-pretty-model-is-smiling_114579-13323.jpg?t=st=1727628950~exp=1727632550~hmac=f57c0f4988f784a7477d4ebf26d835822d98cd149eadbb1db0b1086068bef5c8&w=1800" />,
    name: 'Kavita Rao',
    occupation: 'Project Manager',
    testimonial:
      "The scheduled reminder notifications kept me organized and proactive in my job search. It's a platform that empowers project managers to pursue challenging opportunities with confidence.",
  },
];

const TestimonialCard = ({ testimonial }) => (
  <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 300,
      height: '100%',
      boxShadow: 2,
      flexShrink: 0,
      mr: 2, // Add space between cards
    }}
  >
    <CardContent>
      <Typography variant="body2" color="text.secondary">
        {testimonial.testimonial}
      </Typography>
    </CardContent>
    <CardHeader
      avatar={testimonial.avatar}
      title={testimonial.name}
      subheader={testimonial.occupation}
    />
  </Card>
);

const ScrollingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'hidden',
  width: '100%',
  '&:hover .scrolling-content': {
    animationPlayState: 'paused',
  },
}));

const ScrollingContent = styled(Box)(({ theme, direction }) => ({
  display: 'flex',
  animation: `scroll${direction} 60s linear infinite`,
  transition: 'transform 0.5s ease',
  '@keyframes scrollLeft': {
    '0%': { transform: 'translateX(0)' },
    '100%': { transform: 'translateX(calc(-300px * 3 - 16px * 3))' },
  },
  '@keyframes scrollRight': {
    '0%': { transform: 'translateX(calc(-300px * 3 - 16px * 3))' },
    '100%': { transform: 'translateX(0)' },
  },
}));

const ScrollingRow = ({ testimonials, direction }) => {
  return (
    <ScrollingContainer>
      <ScrollingContent className="scrolling-content" direction={direction}>
        {[...testimonials, ...testimonials].map((testimonial, index) => (
          <TestimonialCard key={`${testimonial.name}-${index}`} testimonial={testimonial} />
        ))}
      </ScrollingContent>
    </ScrollingContainer>
  );
};

export default function Testimonials() {
  const theme = useTheme();

  return (
    <Container
      id="testimonials"
      maxWidth={false}
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary" gutterBottom>
          Hear From More People Like You
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Hear from our users how Joblixx has revolutionized their job search experience. Join us for excellence, innovation, and steadfast support.
        </Typography>
        <Box sx={{
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto',
            textAlign: 'center',
          }}>
        <Grid container spacing={2} >
          <Grid item xs={4}>
            <Box display={'block'}>
              <Typography color='primary.dark' variant='h4' fontSize='clamp(1.4rem, 5vw, 2rem)'>3 Lakh+</Typography>
              <Typography variant='body1' fontSize='clamp(0.6rem, 3vw, 1rem)'>Total Jobs Available</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display={'block'}>
              <Typography color='primary.dark' variant='h4' fontSize='clamp(1.4rem, 5vw, 2rem)'>1000+</Typography>
              <Typography variant='body1' fontSize='clamp(0.6rem, 3vw, 1rem)'>Total users</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display={'block'}>
              <Typography color='primary.dark' variant='h4' fontSize='clamp(1.4rem, 5vw, 2rem)'>200+</Typography>
              <Typography variant='body1' fontSize='clamp(0.6rem, 3vw, 1rem)'>Total hours saved</Typography>
            </Box>
          </Grid>
        </Grid>
        </Box>
      </Box>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <ScrollingRow testimonials={userTestimonials.slice(0, 6)} direction="Left" />
      </Box>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <ScrollingRow testimonials={userTestimonials.slice(2)} direction="Right" />
      </Box>
    </Container>
  );
}