import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: "How do I contact customer support if I have a question or issue?",
    answer: (
      <>
        You can contact our customer support team by emailing <Link href='mailto:support@joblixx.com'>support@joblixx.com</Link>. We are committed to assisting you promptly and ensuring your experience with Joblixx is smooth and enjoyable.
      </>
    ),
  },
  {
    question: "How are jobs matched automatically to my profile?",
    answer: "Jobs are matched to your profile based on the information you provide, such as your job interests, experience, industry, and skills. Keeping your profile updated ensures you receive accurate and relevant job matches."
  },
  {
    question: "What is the limit for Schedulers? How to set it up?",
    answer: "The number of schedulers you can create depends on your subscription plan. Some plans offer unlimited schedulers for managing multiple roles. Each scheduler can be customized with a name, description, communication mode, and preferred time slots. You can manage and configure your schedulers in the 'Notification' section of the application."
  },
  {
    question: "How does the cancellation policy work?",
    answer: "You can cancel your plan at any time during its duration. Future payments will be terminated, along with all services associated with the selected plan. Please note that this action is permanent and cannot be undone once initiated. For further details, refer to our Refund and Cancellation Policy."
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
        If you have any further question, please visit our <Link href='/help-center'>Help Center</Link>.
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqData.map((faq, index) => (
          <Accordion
            key={`panel${index + 1}`}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}d-content`}
              id={`panel${index + 1}d-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}