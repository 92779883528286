import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const GradientText = ({ children, gradient, ...props }) => {
  const theme = useTheme();

  const gradientTextStyle = {
    background: theme.palette.gradients[gradient],
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline',
  };

  return (
    <Typography style={gradientTextStyle} {...props}>
      {children}
    </Typography>
  );
};

GradientText.propTypes = {
  children: PropTypes.node.isRequired,
  gradient: PropTypes.oneOf(['gradient1', 'gradient2', 'gradient3']).isRequired,
};

export default GradientText;
