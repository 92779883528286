import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import GradientText from './assets/GradientText'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTheme } from '@mui/material/styles'

const words = ['Job Search?', 'Opportunities?', 'Career Path?', 'Your Future?']

export default function Hero() {
  const [text, setText] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [loopNum, setLoopNum] = useState(0)
  const [typingSpeed, setTypingSpeed] = useState(130)
  const theme = useTheme()

  useEffect(() => {
    let timer

    const handleTyping = () => {
      const i = loopNum % words.length
      const fullText = words[i]

      setText(
        isDeleting
          ? fullText.substring(0, text.length - 1)
          : fullText.substring(0, text.length + 1)
      )

      setTypingSpeed(isDeleting ? 80 : 130)

      if (!isDeleting && text === fullText) {
        setTimeout(() => setIsDeleting(true), 500)
      } else if (isDeleting && text === '') {
        setIsDeleting(false)
        setLoopNum(loopNum + 1)
      }
    }

    timer = setTimeout(handleTyping, typingSpeed)

    return () => clearTimeout(timer)
  }, [text, isDeleting, loopNum, typingSpeed])

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse 200% 90% at 50% -20%, hsl(200, 65%, 75%), transparent)'
            : 'radial-gradient(ellipse 200% 90% at 50% -20%, hsl(200, 100%, 20%), transparent)',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 4, sm: 8 },
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '80%' } }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
              gap: { xs: '0.5rem', sm: '0.5rem' },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: 'clamp(2.5rem, 5vw, 4rem)',
                textAlign: 'center',
                minHeight: { xs: '3.5rem', sm: '4.5rem' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>{text}</span>
                <span className="blinking-cursor">|</span>
              </Box>
            </Typography>
            <GradientText
              gradient='gradient1'
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(2.5rem, 5vw, 4rem)',
              }}
            >
              Simplified.
            </GradientText>
          </Box>
          <Typography
            variant="h5"
            textAlign="center"
            color="text.primary"
            sx={{ width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 3vw, 1.4rem)' }}
          >
            Let us do the job hunting while you focus on what matters.
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ width: { sm: '100%', md: '90%' }, mt: 1 }}
          >
            Say goodbye to endless searching. Just create a profile, and our smart technology does the rest! connecting you to the best-fit roles so you can get hired faster, easier, and stress-free.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '200px', sm: 'auto' } }}
          >
            <Button
              variant="contained"
              color="primary"
              // href="https://app.joblixx.com/initial-info" // Uncomment when App is live
              href="/coming-soon"
              endIcon={<ArrowForwardIcon />}
              sx={{ minWidth: { xs: '200px' }, minHeight: '50px', borderRadius: 15, fontSize: '1.2rem' }}
            >
              Explore jobs
            </Button>
          </Stack>
        </Stack>
      </Container>
      <style jsx global>{`
        @keyframes blink {
          0% { opacity: 0; }
          50% { opacity: 1; }
          100% { opacity: 0; }
        }
        .blinking-cursor {
          animation: blink 1s infinite;
          font-weight: 100;
          font-size: 1em;
          line-height: 1;
          color: ${theme.palette.primary.main};
        }
      `}</style>
    </Box>
  )
}