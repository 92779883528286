import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';

const SideHeading = styled(Typography)(({ theme }) => ({
    marginTop: "30px",
    fontWeight: 600,
    color: theme.palette.primary.main,
  }));

const Paragraph = styled(Typography)(({ theme }) => ({
    marginTop: "15px",
    color: theme.palette.text.primary,
    }));

const SubSideHeading = styled(Typography)(({ theme }) => ({
    marginTop: "15px",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    }));

export default function Terms() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            // ? 'radial-gradient(ellipse 100% 30% at 50% -20%, hsl(210, 100%, 70%), transparent)'
            ? 'radial-gradient(ellipse 200% 25% at 50% -20%, hsl(210, 100%, 60%), transparent)'
            : 'radial-gradient(ellipse 100% 40% at 50% -20%, hsl(210, 100%, 38%), transparent)',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
        //   alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Typography 
            variant='h1'
            textAlign="center"
            fontSize="40px"
            mb={4}
        >
            Terms of Service (ToS) / Terms and Conditions (T&C)
        </Typography>
        <Typography 
            variant='h6'
            fontWeight="600"
            color="primary.main"
        >
            Introduction
        </Typography>
        <Paragraph>
            We, at Polymath Pvt Ltd (hereinafter referred to as "Joblixx"), are committed to respecting your online privacy and recognize the need for appropriate protection and management of any personally identifiable information you share with us. This Privacy Policy ("Policy") describes how Joblixx collects, uses, discloses, and transfers personal information of users through its websites and applications, including through Joblixx, mobile applications, and online services (collectively, the "Platform"). This policy applies to those who visit the Platform or whose information Joblixx otherwise receives in connection with its services (such as contact information of individuals associated with Joblixx including partners) (hereinafter collectively referred to as "Users"). For the purposes of the Privacy Policy, "You" or "Your" shall mean the person who is accessing the Platform.
        </Paragraph>
        <SideHeading variant='h6'>
            Types of Personal Information collected by Joblixx
        </SideHeading>
        <Paragraph>
            "Personal information" (PI) means any information relating to an identified or identifiable natural person including common identifiers such as a name, an identification number, location data, an online identifier, or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person and any other information that is so categorized by applicable laws. We collect information about you and/or your usage to provide better services and offerings. The Personal Information that we collect, and how we collect it, depends upon how you interact with us. We collect the following categories of Personal Information in the following ways:
        <ul>
            <li>Name, email address, password, country, city, contact number, and company/organization that you are associated with when you sign up for alerts on the Platform.</li>
            <li>Information typically included in a resume, including name, contact details, work experience, educational qualifications, data relating to your current and past remuneration or salary, a copy of your resume, etc., when you register on the Platform.</li>
            <li>Information about the services that you use and how you use them, including log information and location information, when you are a user of the services through the Platform.</li>
            <li>Personal Information such as name, age, contact details, preferences, etc., through surveys and forms, when you choose to participate in these surveys, etc.</li>
            <li>Information relating to your caste and information about whether you are eligible for any affirmative action programs or policies, if you opt to provide such information.</li>
            <li>Communication data when you communicate with Joblixx or use the Platform to communicate with other Users (such as partners), including information about your communication and any information you choose to provide.</li>
            <li>Technical information when you visit the Platform, we use cookies to automatically collect, store, and use technical information about your system and interaction with our Platform and the services used after accessing the Platform.</li>
            <li>Device information when you use a device to access the Platform, Joblixx may collect technical or other related information from the device as well as the device location.</li>
            <li>Social media information if you choose to sign in with your social media account to access the Platform, or otherwise connect your social media account with the services of the Platform, you consent to our collection, storage, and use, in accordance with this Privacy Policy, of the information that you make available to us through the social media interface. Please see your social media provider's privacy policy and help centre for more information about how they share information when you choose to connect your account.</li>
        </ul>
        </Paragraph>
        <SideHeading variant='h6'>
            How Joblixx may use your Personal Information
        </SideHeading>
        <Paragraph>
            We will only use your personal data in a fair and reasonable manner, and where we have a lawful reason to do so.
        </Paragraph>
        <SubSideHeading variant='h6'>
            Data Usage
        </SubSideHeading>
        <Paragraph>
            Our use of your personal data depends on the purpose for which you interact with us. We may process your Personal Information for the following purposes:
            <ul>
                <li>Providing our services and products to you including to send you job alerts, calendar alerts, relevant search results, recommended jobs and/or candidates (as the case may be), and other social media communication facilities.</li>
                <li>Protecting our Users and providing you with customer support.</li>
                <li>Using information collected from cookies and other technologies to improve your user experience and the overall quality of our services. When showing you tailored ads, we will not associate an identifier from cookies or similar technologies with sensitive categories, such as those based on race, religion, sexual orientation, or health.</li>
                <li>Improving the Platform and its content to provide better features and services.</li>
                <li>Conducting market research and surveys with the aim of improving our products and services.</li>
                <li>Sending you information about our products and services (including those of our affiliates) for marketing purposes and promotions.</li>
                <li>Preventing, detecting, investigating, and taking action against crimes (including but not limited to fraud and other financial crimes), any other illegal activities, suspected fraud, or violations of Joblixx's Terms of Use in any jurisdiction.</li>
                <li>To the extent required for identity verification, government sanctions screening, and due diligence checks.</li>
                <li>Establishing, exercising, or defending legal rights in connection with legal proceedings (including any prospective legal proceedings) and seeking professional or legal advice in relation to such legal proceedings.</li>
            </ul>
        </Paragraph>
        <SideHeading variant='h6'>
            Cookies and Other Tracking Technologies
        </SideHeading>
        <Paragraph>
            Some of our web pages utilize "cookies" and other tracking technologies. A "cookie" is a small text file that may be used, for example, to collect information about web-site activity. Some cookies and other technologies may serve to recall Personal Information previously indicated by a user. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them.
            You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, but please note that if you choose to erase or block your cookies, you will need to re-enter your original user ID and password to gain access to certain parts of the Platform.
            Tracking technologies may record information such as Internet domain and host names; Internet protocol (IP) addresses; browser software and operating system types; clickstream patterns; and dates and times that our site is accessed. Our use of cookies and other tracking technologies allows us to improve our Platform and the overall website experience. We may also analyze information that does not contain Personal Information for trends and statistics.
            For more information about our use of cookies please refer to our <Link href='/cookie-policy' color="primary">Cookie Policy</Link>.
        </Paragraph>
        <SideHeading variant='h6'>
            The Basis/ Grounds which we rely on for collection and processing of your Personal Information
        </SideHeading>
        <Paragraph>
            Your Personal Information is collected and processed by Joblixx based on the following legal grounds depending upon the nature of Personal Information and the purposes for which it is processed.
        </Paragraph>
        <SubSideHeading variant='h6'>Consent</SubSideHeading>
        <Paragraph>
            Joblixx relies on your consent to process your Personal Information in certain situations. If Joblixx requires your consent to collect and process certain Personal Information, as per the requirements under the applicable data protection laws, your consent is sought at the time of collection of your Personal Information, and such processing will only be performed where consent is secured.
        </Paragraph>
        <SubSideHeading variant='h6'>Compliance with a Legal Obligation</SubSideHeading>
        <Paragraph>
            Your Personal Information may be processed by Joblixx to the extent that such processing is necessary to allow Joblixx to comply with a legal obligation. An example of this would be if Joblixx is required to disclose your Personal Information to respond to a court order or if Joblixx is required to retain specific records for a fixed period to comply with requirements under any applicable law.
        </Paragraph>
        <SideHeading variant='h6'>
            Information Sharing and Disclosure
        </SideHeading>
        <Paragraph>
            We restrict access to your Personal Information to employees who we believe reasonably need to know that information to fulfil their jobs to provide, operate, develop, or improve our products or services.
            Joblixx does not disclose, transfer, or share your Personal Information with others except with:
            <ul>
                <li>Our affiliates and group companies to the extent required for our internal business and/or administrative purposes and/or general corporate operations and for the provision of services aimed at helping you in your career enhancement.</li>
                <li>Potential recruiters if we determine that your resume matches a particular job description/vacancy available with such recruiters. By registering on the Platform and consenting to the terms of this Privacy Policy, you agree that Joblixx may contact you or forward your resume to potential recruiters.</li>
                <li>Third parties including enforcement, regulatory, and judicial authorities, if we determine that disclosure of your Personal Information is required to:
                    <ul>
                        <li>Respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.</li>
                        <li>Investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Joblixx’s Terms of Use or as otherwise required by law.</li>
                    </ul>
                </li>
                <li>In the event of a merger, acquisition, financing, or sale of assets or any other situation involving the transfer of some or all of Joblixx’s business assets, we may disclose Personal Information to those involved in the negotiation or transfer.</li>
                <li>Third-party service providers and marketing partners that Joblixx engages to:
                    <ul>
                        <li>Provide services over the Platform on Joblixx's behalf.</li>
                        <li>Maintain the Platform and mailing lists.</li>
                        <li>Communicate with you on Joblixx’s behalf about offers relating to its products and/or services. Joblixx will take reasonable steps to ensure that these third-party service providers are obligated to protect your Personal Information and are also subject to appropriate confidentiality/non-disclosure obligations.</li>
                    </ul>
                </li>
                <li>Third-party advertisers to display advertisements to you when you visit the Platform. The third-party advertisers include financial service providers (such as banks, insurance agents, stock brokers, and mortgage lenders) and non-financial companies (such as stores, airlines, and software companies). These companies may use information about you and your visits to this Platform and other websites to provide advertisements on this Platform.</li>
                <li>The Company does not provide any Personal Information to the advertiser when you interact with or view a targeted advertisement. However, if you interact with or view an advertisement, the advertiser may make certain assumptions and, in the process, learn certain Personal Information about you. For instance, if you view and click on an advertisement relating to a particular interest, the advertiser may assume that you meet the targeting criteria used to display the advertisement. Joblixx is not responsible for the use or protection of Personal Information disclosed to such third-party advertisers.</li>
            </ul>
        </Paragraph>
        <SideHeading variant='h6'>
            Cross-border data transfers
        </SideHeading>
        <Paragraph>When we transfer your Personal Information outside of India, we will ensure that it is protected and transferred in a manner consistent with legal requirements applicable to the information. For example, we may transfer your Personal Information to countries which the Government of India or other competent authorities deem to have an adequate level of data protection law. Where we transfer your Personal Information to other countries, we will do so based on European Union Commission-approved Model Contractual Clauses, or other legally recognized transfer mechanisms that ensure an adequate level of protection for your Personal Information.</Paragraph>
        <SideHeading variant='h6'>
            Security
        </SideHeading>
        <Paragraph>
            The security of your Personal Information is important to us. Joblixx has adopted reasonable security practices and procedures, as mandated under applicable laws, to ensure that the Personal Information collected is secure. You agree that such measures are secure and adequate. While we will endeavour to take all reasonable and appropriate steps to keep secure any Personal Information which we hold about you and prevent unauthorized access, you acknowledge that the internet or computer networks are not fully secure. We cannot guarantee the absolute security of your Personal Information during transmission or storage in our systems.In the event of any data breach that compromises your Personal Information, we will take appropriate steps to address the breach in accordance with applicable laws and notify you, where required, about the breach and the measures we have taken to mitigate its effects.
        </Paragraph>
        <SideHeading variant='h6'>
            Third Party Content
        </SideHeading>
        <Paragraph>
            Please be aware that the Platform sometimes contains links to other sites that are not governed by this Privacy Policy. Users may be directed to third-party sites for more information, such as advertisers, blogs, content sponsorships, vendor services, social networks, etc. Joblixx makes no representations or warranties regarding how your information is stored or used on third-party servers. We recommend that you review the applicable privacy statements and policies of each third-party site linked from the Platform to determine their use of your Personal Information.
            While we strive to ensure that we only partner with reputable third-party sites and services, we are not responsible for the privacy practices or the content of these external sites. Clicking on third-party links may allow third parties to collect or share data about you. We encourage you to read the privacy policies of any third-party websites you visit.
        </Paragraph>
        <SideHeading variant='h6'>
            User Discretion
        </SideHeading>
        <Paragraph>
            You may choose not to provide identifiable information through the Platform, in which case you may not be allowed to access certain features or parts of the Platform. If you are a registered user, you may update, edit or correct your account information and email preferences at any time by logging in to your account. Joblixx may, at its discretion, contact you to inform you about new features, services, and products from Joblixx. If you do not wish to receive such communications, you may unsubscribe by following the instructions provided in the communication or by contacting us directly. You also have the option to disable cookies through your browser settings, though this may affect the functionality of the Platform and your ability to use certain features.
        </Paragraph>
        <SideHeading variant='h6'>
            Retention of Personal Information
        </SideHeading>
        <Paragraph>
            Joblixx will retain your Personal Information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for Personal Information, we consider the amount, nature, and sensitivity of the Personal Information, the potential risk of harm from unauthorized use or disclosure of your Personal Information, the purposes for which we process your Personal Information, and whether we can achieve those purposes through other means, as well as the applicable legal requirements.
            Once the retention period expires, we will securely delete or anonymize your Personal Information in accordance with applicable laws and regulations.
        </Paragraph>
        <SideHeading variant='h6'>
            Changes to this Policy
        </SideHeading>
        <Paragraph>
            Joblixx may update this Privacy Policy from time to time to reflect changes to our information practices or legal requirements. We will provide notice of any significant changes by posting the new Privacy Policy on our Platform. We encourage you to periodically review this page for the latest information on our privacy practices.
        </Paragraph>
        <SideHeading variant='h6'>Grievance Redressal</SideHeading>
        <Paragraph>
        If you have any questions or concerns about this Privacy Policy, please feel free to contact us by emailing our grievance officer at <Link href='mailto:contact@joblixx.com' color='primary'>contact@joblixx.com</Link>. Our grievance officer will respond to your queries within 3 days from the date of receipt of your email.
        </Paragraph>
      </Container>
    </Box>
  );
}
