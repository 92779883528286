import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import GradientText from './assets/GradientText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PersonIcon from '@mui/icons-material/Person';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const items = [
  {
    icon: <ElectricBoltIcon />,
    title: 'Lightning fast',
    description:
      "Our advanced, automated Schedulers works around the clock, analyzing job openings that perfectly align with your skills, preferences, and career goals. With intelligent matching, you'll receive curated opportunities tailored to within minutes of creating youuur profile and ensuring you never miss out on the perfect role.",
  },
  {
    icon: <NotificationsActiveIcon />,
    title: 'QuickHire',
    description:
      "Stay ahead of the competition with real-time alerts for job opportunities that match your profile. Our system ensures you're the first to know when a new role opens up, so you can seize the moment and apply before anyone else.",
  },
  {
    icon: <AutoAwesomeIcon />,
    title: 'Summarizer',
    description:
      "Cut through the clutter and get straight to the essentials. Our summarizer delivers concise, easy-to-read overviews of job postings, highlighting the key details that matter most—so you can make faster, informed decisions without sifting through lengthy descriptions.",
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Personalization',
    description:
      "Get matched with job opportunities that truly fit your unique skills, experience, and career goals. Our system filters through countless listings to find the roles that align with your profile—ensuring every opportunity is relevant, personalized, and worth your time.",
  },
  {
    icon: <SavedSearchIcon />,
    title: 'Multisearch',
    description:
      "Run job searches for different roles and locations simultaneously with our scheduling tool. Maximize your opportunities by managing multiple searches in parallel—saving you time and increasing your chances of finding the right job faster.",
  },
  {
    icon: <PersonIcon />,
    title: 'Profile',
    description:
      "Create a visually appealing, ATS-compliant public profile that you can easily download and share with recruiters. Once your profile is complete, it's automatically visible to employers, helping you stand out effortlessly.",
  },
];

const GradientIcon = ({ children }) => (
  <Box
    sx={{
      width: 60,
      height: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(45deg, #1f6c93 30%, #0d2e3f 90%)',
      borderRadius: '50%',
      color: 'primary.light',
    }}
  >
    {React.cloneElement(children, { sx: { fontSize: 40 } })}
  </Box>
);

export default function Highlights() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      id="services"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'hsl(220, 30%, 2%)',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            What Sets Us Apart
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400', mb: 2 }}>
            Match with hundreds of jobs automatically and start applying instantly! Check below the features that make Joblixx stand out!
          </Typography>
          <Button 
            variant="contained" 
            size="large" 
            // href="https://app.joblixx.com/initial-info" // Uncomment when App is live
            href="/coming-soon" 
            sx={{ 
              minWidth: 200,
              fontSize: '1.1rem',
              py: 1.5,
              px: 4,
              mt: 2,
              borderRadius: 15,
              color: 'background.default'
            }}
          >
            Apply to Jobs
          </Button>
        </Box>
        <Box
          sx={{
            width: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              height: 6,
              WebkitAppearance: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
            },
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mb: 2,
              pb: 2,
              width: isMobile ? 'calc(100% * 6)' : 'calc(30% * 6)',
            }}
          >
            {items.map((item, index) => (
              <Card
                key={index}
                sx={{
                  width: isMobile ? '100vw' : '40vw',
                  height: '399px',
                  p: 3,
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, .3)',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  boxShadow: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <GradientIcon>{item.icon}</GradientIcon>
                </Box>
                <GradientText fontWeight="medium" gradient='gradient1' gutterBottom variant="h4">
                  {item.title}
                </GradientText>
                <Typography variant="body1" sx={{ color: 'grey.400', flexGrow: 1 }}>
                  {item.description}
                </Typography>
              </Card>
            ))}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}