import * as React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export default function SitemarkIcon(props) {
  const handleClick = () => {
    window.location.href = '/'
  }

  return (
    <SvgIcon
      sx={{ height: 30, width: 150 }}
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
      viewBox="0 -20 910.4 191.5"
      {...props}
    >
      <g>
        <g>
          <path
            fill="#2c9bd2"
            d="M225.3,136.6c-6.3-2.8-11.5-6.8-15.6-12.1l14.4-17.3c6.1,8.2,13,12.3,20.8,12.3c10.6,0,15.8-6.2,15.8-18.5 V47.8h-39.3V27h65.1v72.6c0,13.8-3.4,24.1-10.2,30.9c-6.8,6.9-16.8,10.3-30.1,10.3C238.6,140.9,231.7,139.4,225.3,136.6z"
          />
          <path
            fill="#2c9bd2"
            d="M326.9,134.5c-7.2-3.8-12.8-9-16.9-15.8c-4.1-6.7-6.1-14.3-6.1-22.9c0-8.5,2-16.2,6.1-22.9 c4-6.7,9.7-12,16.9-15.8c7.2-3.8,15.3-5.7,24.4-5.7c9.1,0,17.2,1.9,24.3,5.7c7.1,3.8,12.7,9,16.8,15.8c4,6.7,6.1,14.3,6.1,22.9 c0,8.5-2,16.2-6.1,22.9c-4.1,6.7-9.6,12-16.8,15.8c-7.1,3.8-15.2,5.7-24.3,5.7C342.2,140.2,334.1,138.3,326.9,134.5z M367,113.3 c4.1-4.3,6.2-10.1,6.2-17.3c0-7.2-2.1-13-6.2-17.3c-4.1-4.3-9.4-6.5-15.8-6.5c-6.4,0-11.7,2.2-15.8,6.5 c-4.2,4.3-6.2,10.1-6.2,17.3c0,7.2,2.1,13,6.2,17.3c4.2,4.3,9.4,6.5,15.8,6.5C357.6,119.7,362.9,117.6,367,113.3z"
          />
          <path
            fill="#2c9bd2"
            d="M487.7,57.1c6.6,3.7,11.7,8.8,15.4,15.5c3.7,6.7,5.6,14.4,5.6,23.3c0,8.8-1.9,16.6-5.6,23.3 c-3.7,6.7-8.9,11.8-15.4,15.5c-6.6,3.7-13.8,5.5-21.8,5.5c-11.8,0-20.8-3.7-27-11.2v9.9h-23.8V20.3H440V62 c6.3-6.9,14.9-10.4,25.9-10.4C473.9,51.6,481.2,53.5,487.7,57.1z M477.3,113.3c4.1-4.3,6.2-10.1,6.2-17.3c0-7.2-2.1-13-6.2-17.3 c-4.1-4.3-9.4-6.5-15.8-6.5c-6.4,0-11.6,2.2-15.8,6.5c-4.1,4.3-6.2,10.1-6.2,17.3c0,7.2,2.1,13,6.2,17.3c4.1,4.3,9.4,6.5,15.8,6.5 C468,119.7,473.2,117.6,477.3,113.3z"
          />
          <path fill="#2c9bd2" d="M525.4,20.3h24.9v118.6h-24.9V20.3z" />
          <path
            fill="#2c9bd2"
            d="M574.8,36.9c-2.9-2.7-4.3-6-4.3-9.9c0-3.9,1.4-7.2,4.3-9.9c2.9-2.7,6.6-4,11.2-4c4.6,0,8.3,1.3,11.2,3.8 c2.9,2.6,4.3,5.8,4.3,9.6c0,4.2-1.4,7.6-4.3,10.3s-6.6,4.1-11.2,4.1C581.4,40.9,577.7,39.6,574.8,36.9z M573.5,52.9h24.9v86h-24.9 V52.9z"
          />
          <path
            fill="#2c9bd2"
            d="M676.3,138.9l-18.9-26.4L638,138.9h-27.2l33.1-43.3l-32-42.7h28L658.1,78l18.7-25.1h26.5l-32,42.1l33.3,44 H676.3z"
          />
          <path
            fill="#2c9bd2"
            d="M771.5,138.9l-18.9-26.4l-19.5,26.4h-27.2L739,95.6l-32-42.7h28L753.2,78L772,52.9h26.5l-32,42.1l33.3,44 H771.5z"
          />
        </g>
        <g>
          <path
            fill="#2c9bd2"
            d="M60.2,0.1L31.5,7.3c-3.2,0.8-4.6,4.7-2.5,7.3l33.8,42.3c0.9,1.1,2.2,1.7,3.6,1.7h35.2c1.4,0,2.7-0.6,3.6-1.7 l33.8-42.3c2.1-2.6,0.8-6.5-2.5-7.3l-28.7-7.2c-2.3-0.6-4.6,0.7-5.4,2.9l-9.9,27.7c-0.6,1.8-2.4,3-4.3,3h-8.4 c-1.9,0-3.7-1.2-4.3-3L65.6,3.1C64.8,0.8,62.5-0.4,60.2,0.1z"
          />
          <g>
            <path
              fill="#2c9bd2"
              d="M159.8,89.9l8.1-28.4c0.9-3.2-1.8-6.3-5.1-5.8l-53.5,8.2c-1.4,0.2-2.6,1-3.3,2.2L88.4,96.6 c-0.7,1.2-0.8,2.7-0.3,4l19.7,50.4c1.2,3.1,5.3,3.9,7.6,1.5l20.5-21.2c1.6-1.7,1.7-4.4,0.2-6.2L117,102.7 c-1.3-1.5-1.4-3.6-0.5-5.3l4.2-7.3c1-1.7,2.9-2.6,4.8-2.2l29,5.3C156.9,93.6,159.1,92.2,159.8,89.9z"
            />
            <path
              fill="#2c9bd2"
              d="M32.2,131.3l20.5,21.2c2.3,2.4,6.4,1.6,7.6-1.5L80,100.6c0.5-1.3,0.4-2.8-0.3-4L62.1,66.1 c-0.7-1.2-1.9-2-3.3-2.2L5.3,55.7c-3.3-0.5-6,2.6-5.1,5.8l8.1,28.4c0.6,2.3,2.9,3.7,5.2,3.3l29-5.3c1.9-0.3,3.8,0.5,4.8,2.2 l4.2,7.3c1,1.7,0.8,3.8-0.5,5.3L32,125.1C30.4,126.9,30.5,129.6,32.2,131.3z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}