import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/system';
// Light Logos
import GoogleLogoLight from '../assets/img/LogoCollection/light/google-logo.svg';
import DeloitteLogoLight from '../assets/img/LogoCollection/light/deloitte-logo.svg';
import NetflixLogoLight from '../assets/img/LogoCollection/light/netflix-logo.svg';
import AmazonLogoLight from '../assets/img/LogoCollection/light/amazon-logo.svg';
import NvidiaLogoLight from '../assets/img/LogoCollection/light/nvidia-logo.svg';
import MetaLogoLight from '../assets/img/LogoCollection/light/meta-logo.svg';
import AccentureLight from '../assets/img/LogoCollection/light/accenture-logo.svg';
import CiscoLight from '../assets/img/LogoCollection/light/cisco-logo.svg';
import CongnizantLight from '../assets/img/LogoCollection/light/cognizant-logo.svg';
import HsbcLight from '../assets/img/LogoCollection/light/hsbc-logo.svg';
import InfosysLight from '../assets/img/LogoCollection/dark/infosys-logo.svg';
import JpMorganLight from '../assets/img/LogoCollection/dark/jp-morgan-logo.svg';
import MicrosoftLight from '../assets/img/LogoCollection/dark/microsoft-logo.svg';
import OracleLight from '../assets/img/LogoCollection/dark/oracle-logo.svg';
import WellFargoLight from '../assets/img/LogoCollection/dark/wells-fargo-logo.svg';
import WalmartLight from '../assets/img/LogoCollection/light/walmart-logo.svg';
import PepsicoLight from '../assets/img/LogoCollection/light/pepsico-logo.svg';
// Dark Logos
import GoogleLogoDark from '../assets/img/LogoCollection/dark/google-logo.svg';
import DeloitteLogoDark from '../assets/img/LogoCollection/dark/deloitte-logo.svg';
import NetflixLogoDark from '../assets/img/LogoCollection/dark/netflix-logo.svg';
import AmazonLogoDark from '../assets/img/LogoCollection/dark/amazon-logo.svg';
import NvidiaLogoDark from '../assets/img/LogoCollection/dark/nvidia-logo.svg';


const whiteLogos = [
  GoogleLogoDark,
  AmazonLogoDark,
  // MetaLogoDark,
  NetflixLogoDark,
  NvidiaLogoDark,
  DeloitteLogoDark,
];

const darkLogos = [
  GoogleLogoLight,
  AmazonLogoLight,
  MetaLogoLight,
  NetflixLogoLight,
  NvidiaLogoLight,
  DeloitteLogoLight,
  AccentureLight,
  CiscoLight,
  CongnizantLight,
  HsbcLight,
  InfosysLight,
  JpMorganLight,
  MicrosoftLight,
  OracleLight,
  WellFargoLight,
  WalmartLight,
  PepsicoLight
];

const logoStyle = {
  width: '100px',
  height: '100px',
  margin: '0 32px',
  opacity: 0.5,
};

const AnimationContainer = styled(Box)({
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  whiteSpace: 'nowrap',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '20%',
    zIndex: 1,
  },
  '&::before': {
    left: 0,
    background: 'linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,-20))',
  },
  '&::after': {
    right: 0,
    background: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,-3))',
  },
});

const AnimatedBox = styled(Box)({
  display: 'flex',
  animation: 'moveLeft 60s linear infinite',
  '@keyframes moveLeft': {
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(-50%)',
    },
  },
});

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;
  const allLogos = [...logos, ...logos, ...logos]; // Triple the logos for seamless loop

  return (
    <Box alignContent='centre' id="logoCollection" sx={{ py:2 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        3 lakh+ jobs from the best companies
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0.5 }}>
        <AnimationContainer>
          <AnimatedBox >
            {allLogos.map((logo, index) => (
              <img
                key={index}
                src={logo}
                style={logoStyle}
              />
            ))}
          </AnimatedBox>
        </AnimationContainer>
      </Box>
    </Box>
  );
}
