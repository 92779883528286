import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';

const SideHeading = styled(Typography)(({ theme }) => ({
    marginTop: "30px",
    fontWeight: 600,
    color: theme.palette.primary.main,
  }));

const Paragraph = styled(Typography)(({ theme }) => ({
    marginTop: "15px",
    color: theme.palette.text.primary,
    }));

const SubSideHeading = styled(Typography)(({ theme }) => ({
    marginTop: "15px",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    }));

export default function Cookie() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            // ? 'radial-gradient(ellipse 100% 30% at 50% -20%, hsl(210, 100%, 70%), transparent)'
            ? 'radial-gradient(ellipse 200% 25% at 50% -20%, hsl(210, 100%, 60%), transparent)'
            : 'radial-gradient(ellipse 100% 40% at 50% -20%, hsl(210, 100%, 38%), transparent)',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
        //   alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Typography 
            variant='h1'
            textAlign="center"
            fontSize="40px"
        >
            Cookie Policy
        </Typography>
        <SideHeading variant='h6'>
            Introduction
        </SideHeading>
        <Paragraph>
        At Joblixx, we believe in being transparent about how we use your information. This Cookie Policy ("Policy") explains how and when we use cookies on our website and platform. This Policy applies to any Joblixx product or service that links to or incorporates it by reference.
        </Paragraph>
        <SideHeading variant='h6'>
            Does Joblixx Use Cookies?
        </SideHeading>
        <Paragraph>
        Yes, Joblixx uses cookies, tags, and other technologies when you use our websites, mobile sites, mobile apps, and any third-party services through the Joblixx platform. Cookies help us provide the best possible experience and keep your account secure. By continuing to use our services, you agree to the use of cookies as described in this Policy. If you prefer not to receive cookies, you can manage your cookie settings as described below.
        </Paragraph>
        <SideHeading variant='h6'>
        What is a Cookie?
        </SideHeading>
        <Paragraph>
        Cookies are small pieces of text stored by a website in your browser. They help the site recognise you and make your next visit easier and more useful. Cookies allow Joblixx to serve you better, personalise your experience, and enhance security. Cookies can be "persistent" or "session" cookies. Persistent cookies stay in your browser after you sign in and are read when you return to our site. Session cookies only last for the duration of your session.
        </Paragraph>
        <SideHeading variant='h6'>
        What Are Cookies Used For?
        </SideHeading>
        <Paragraph>
        We use cookies for several purposes:
        <ul>
            <li>Essential Cookies: Necessary for the basic functioning of the site, such as keeping you logged in.</li>
            <li>Analytics Cookies: Track visits and usage to help us improve our services.</li>
            <li>Functionality Cookies: Remember your preferences to personalise your experience.</li>
            <li>Targeting/Advertising Cookies: Deliver relevant ads and track their performance.</li>
        </ul>
        </Paragraph>
        <SideHeading variant='h6'>
        Types of Cookies We Use
        </SideHeading>
        <Paragraph>
        We use the following categories of cookies:
        <ul>
            <li>Essential Cookies: These are crucial for the operation of our website. They remember your preferences as you navigate the site and keep you logged in.</li>
            <li>Analytics Cookies: These cookies help us understand user behaviour on our site, such as which pages are visited most often and how users interact with the site.</li>
            <li>Functionality Cookies: These cookies remember choices you make, such as your username, language, or region, to provide a more personalised experience.</li>
            <li>Targeting/Advertising Cookies: These cookies are used to deliver ads relevant to your interests, both on our site and elsewhere.</li>
        </ul>
        </Paragraph>
        <SideHeading variant='h6'>
        How We Use Cookies
        </SideHeading>
        <Paragraph>
        We use cookies to:
        <ul>
            <li>Remember your login details and preferences</li>
            <li>Analyze site usage to improve performance</li>
            <li>Deliver personalised content and advertisements</li>
            <li>Conduct research and diagnostics to enhance our services</li>
        </ul>
        </Paragraph>
        <SideHeading variant='h6'>
        Managing Cookies
        </SideHeading>
        <Paragraph>
        You can manage or disable cookies through your browser settings. Here are links to manage cookies in popular browsers:
        <ul>
            <li>Google Chrome: <Link color='primary.main' href='https://support.google.com/chrome/answer/95647'>https://support.google.com/chrome/answer/95647</Link></li>
            <li>Mozilla Firefox: <Link color='primary.main' href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'>https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</Link></li>
            <li>Safari: <Link color='primary.main' href='https://support.apple.com/en-us/HT201265'>https://support.apple.com/en-us/HT201265</Link></li>
            <li>Microsoft Edge: <Link color='primary.main' href='https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies'>https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies</Link></li>
        </ul>
        Please note that disabling cookies may affect the functionality of our site.
        </Paragraph>
        <SideHeading variant='h6'>
        Third-Party Cookies
        </SideHeading>
        <Paragraph>
        We use third-party cookies for analytics and advertising. For example, we use Google Analytics to understand how users interact with our site. These third-party services may place cookies on your device and collect information about your browsing habits.
        </Paragraph>
        <SideHeading variant='h6'>
        User Consent
        </SideHeading>
        <Paragraph>
        By using our site, you consent to the use of cookies as described in this Policy. You can withdraw your consent at any time by managing your cookie preferences through your browser settings.
        </Paragraph>
        <SideHeading variant='h6'>Changes to This Cookie Policy</SideHeading>
        <Paragraph>We may update this Cookie Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes through email or a notice on our site. Please check back regularly to stay informed about our use of cookies.</Paragraph>
        <SideHeading variant='h6'>Contact Information</SideHeading>
        <Paragraph>
        If you have any questions or concerns about our Cookie Policy, please contact us at:
        <ul>
            <li>Email: <Link href='mailto:support@joblixx.com' color='primary.main'>support@joblixx.com</Link></li>
            <li>Address: Polymath Private Limited, 3-5-683/1, Safron Towers, Jubilee Hills, Hyderabad, Telangana, 500080</li>
        </ul>
        </Paragraph>
      </Container>
    </Box>
  );
}
