import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Box, CssBaseline, Divider, Typography, Container, TextField, InputAdornment, Accordion, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import WebAppBar from '../WebAppBar';
import Footer from '../Footer';
import myImage from '../../assets/img/website/duotone.png';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
    {
        "question": "How are jobs matched automatically to my profile?",
        "answer": "Jobs are matched to your profile based on the information you provide, such as your job interests, experience, industry, and skills. Keeping your profile updated ensures you receive accurate and relevant job matches."
    },     
    {
        "question": "How can I increase job matches?",
        "answer": "Regularly update your profile and preferences. Make sure your preferences align with the job titles you've set in the scheduler to increase your chances of receiving better matches."
    },
    {
        "question": "What does 'Search stage' mean?",
        "answer": "'Search stage' indicates your current status in the job search process. Based on this, Joblixx sends you relevant job notifications to keep you informed and engaged."
    },
    {
        "question": "I’m no longer on the market for a new job, what should I do?",
        "answer": (
        <>
            {/* If you're no longer looking for a job and wish to stop receiving job-related notifications, simply update your search status at <Link href='https://app.joblixx.com/preferences'>Preferences page</Link>. This helps Joblixx understand your availability. You can update this setting anytime you decide to resume your job search. // Uncomment when App is live */}
            If you're no longer looking for a job and wish to stop receiving job-related notifications, simply update your search status at <Link href='/coming-soon'>Preferences page</Link>. This helps Joblixx understand your availability. You can update this setting anytime you decide to resume your job search.
        </>
        ),
    }, 
    {
        "question": "I’m back on the market for a new job! How do I get my profile live again?",
        "answer": (
        <>
            {/* If you're ready to restart your job search, simply update your search stage in the <Link href='https://app.joblixx.com/preferences'>Preferences page</Link> tab and create a scheduler. Once updated, Joblixx will recognize your availability and resume your job search seamlessly. // Uncomment when App is live */}
            If you're ready to restart your job search, simply update your search stage in the <Link href='/coming-soon'>Preferences page</Link> tab and create a scheduler. Once updated, Joblixx will recognize your availability and resume your job search seamlessly.
        </>
        ),
    }
              
];

export default function JobMatching() {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // Implement search functionality here
        console.log('Search query:', searchQuery);
    };

    return (
        <Box>
            <Helmet>
                <title>Joblixx - Help center | Job matching</title>
            </Helmet>
            <CssBaseline />
            <WebAppBar />
            <Box position="relative" height="400px">
                <Box
                    sx={{
                        height: '100%',
                        backgroundImage: `url(${myImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        textAlign: 'center',
                        width: '80%'
                    }}
                >
                    <Typography variant="h4" color='primary.lighter' fontSize='clamp(1.8rem,4vw,2.5rem)' gutterBottom>
                        How can we help you?
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSearchSubmit}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            maxWidth: '600px',
                            margin: '0 auto',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for help..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'white',
                                height:'50px',
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Container maxWidth="lg" sx={{ py: 8 }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{textAlign: 'center', mb:4}}>
                        <Typography component='h2' variant='h4' mb={2}>Job matching</Typography>
                        <Typography>Learn how to improve the accuracy of your job matches and streamline your application process for better results.</Typography>
                    </Box>
                    {faqData.map((faq, index) => (
                        <Accordion
                            key={`panel${index + 1}`}
                            expanded={expanded === `panel${index + 1}`}
                            onChange={handleChange(`panel${index + 1}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index + 1}d-content`}
                                id={`panel${index + 1}d-header`}
                            >
                                <Typography component="h3" variant="subtitle1">
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
            <Divider />
            <Footer />
        </Box>
    );
}

// JobMatching.propTypes = {
//     // Add any props if needed
// };