import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Container, IconButton, useTheme, useMediaQuery } from '@mui/material';
import SitemarkIcon from '../SitemarkIcon';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

export default function ComingSoon() {
  const [email, setEmail] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle subscription logic here
    console.log('Subscribed with email:', email);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        bgcolor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb:4
        }}
      >
        <SitemarkIcon  /> {/* Adjust width and height for sizing */}
      </Box>
      
      <Container maxWidth="md">
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          textAlign: 'center' 
        }}>
          <Typography variant="h1" display="block" gutterBottom color="primary.main">
            LAUNCHING SOON
          </Typography>
          <Typography variant="body1" gutterBottom color="text.primary" sx={{ maxWidth: '600px', mx: 'auto' }}>
            Exciting things are on the horizon! We're gearing up to bring you an even better job search experience with powerful new features that will simplify the process like never before. Get ready for smarter tools, personalized insights, and more opportunities to land your dream role—faster and easier.
          </Typography>
          <Typography variant="h5" component="h4" gutterBottom sx={{ mt: 2, fontWeight: 'bold', color: theme.palette.text.primary }}>
            Stay tuned, you won't want to miss what's coming!
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ 
            mt: 2, 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
            maxWidth: '600px'
          }}>
            <TextField
              fullWidth
              placeholder="Email address"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ 
                mr: isMobile ? 0 : 1,
                mb: isMobile ? 1 : 0,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                }
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                height: '56px',
                borderRadius: '15px',
                bgcolor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  bgcolor: theme.palette.primary.dark,
                },
                flexShrink: 0,
                width: isMobile ? '100%' : 'auto',
              }}
            >
              Notify me
            </Button>
          </Box>
          <Box sx={{ mt: 4 }}>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/joblixx/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/TryJoblixx"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://instagram.com/joblixx"
            aria-label="Instagram"
            sx={{ alignSelf: 'center' }}
          >
            <InstagramIcon />
          </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
